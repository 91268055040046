.component,
.componentLinkBase {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-medium);
  letter-spacing: -0.4px;
  padding: var(--size-8) var(--size-24);
  border-radius: var(--size-4);
}

.dark {
  color: var(--color-white);
  background-color: var(--color-blue-800);
  border: 1px solid var(--color-blue-800);
  transition: background-color var(--motion-duration-300) ease-in-out;

  &:hover {
    background-color: var(--color-blue-100);
    border: 1px solid var(--color-blue-800);
    color: var(--color-blue-800);
  }
}

.blue {
  color: var(--color-white);
  background-color: var(--color-blue-400);
  border: 1px solid var(--color-blue-400);
  transition: background-color var(--motion-duration-200) ease-in-out;

  &:hover {
    background-color: var(--color-blue-500);
    border: 1px solid var(--color-blue-500);
  }
}

.white {
  color: var(--color-blue-900);
  background-color: var(--color-white);
  transition: background-color var(--motion-duration-300) ease-in-out;
  border: 1px solid var(--color-white);

  &:hover {
    background-color: var(--color-blue-100);
    border: 1px solid var(--color-white);
    color: var(--color-white);
  }
}
