:root {
  --color-blue-200: #dfe2e5;
  --color-blue-300: #c8e1fc;
  --color-blue-400: #0055f9;
  --color-blue-500: #002fa7;
  --color-blue-600: #647b8f;
  --color-blue-800: #1c2023;
  --color-blue-900: #10151b;

  --color-green: #4ed7b7;
  --color-orange: #f46031;

  --color-gray-100: #f5f5f5;
  --color-gray-150: #f7f8f9;
  --color-gray-200: #dfe2e5;
  --color-gray-300: #687b8d;

  --color-black: #000;
  --color-white: #fff;
}
