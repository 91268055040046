:root {
  /* Fonts */
  --font-family-base: 'Inter', sans-serif;
  --font-family-alt: 'GilroyRegular', 'Inter', sans-serif;
  --font-family-alt-semi-bold: 'GilroySemiBold', 'Inter', sans-serif;
  --font-family-alt-extra-bold: 'GilroyExtraBold', 'Inter', sans-serif;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--m)), 20px);
  --font-size-18: 18px;
  --font-size-18-32: clamp(18px, calc(18px + 14 * var(--m)), 32px);
  --font-size-20: 20px;
  --font-size-20-32: clamp(20px, calc(20px + 12 * var(--m)), 32px);
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-32-62: clamp(32px, calc(32px + 30 * var(--m)), 62px);
  --font-size-32-84: clamp(32px, calc(32px + 52 * var(--m)), 84px);
  --font-size-32-90: clamp(32px, calc(32px + 58 * var(--m)), 90px);
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-48-84: clamp(48px, calc(48px + 36 * var(--m)), 84px);
  --font-size-84: 84px;
  --font-size-84-140: clamp(84px, calc(84px + 56 * var(--m)), 140px);
  --font-size-84-160: clamp(84px, calc(84px + 56 * var(--m)), 160px);
  --font-size-140: 140px;

  /* Leading */
  --line-height-heading: 1;
  --line-height-heading-sm: 1.1;
  --line-height-paragraph: 1.5;
  --line-height-list: 1.8;

  /* Weights */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}
