.component {
  background-color: var(--color-gray-150);
  color: var(--color-black);
}

.componentDark {
  background-color: var(--color-black);
  color: var(--color-white);
}

.componentBase {
  --clip-path: polygon(43.4% 0%, 100% 0, 60% 100%, 0 100%);

  overflow: hidden;
  position: relative;

  @media (--viewport-md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > .navButtons {
    position: absolute;
    top: 50%;
    width: 100%;

    @media (--viewport-md) {
      width: 80%;
    }
  }
}

.layout {
  position: relative;

  & > .progressBar {
    margin-top: var(--size-32);
    margin-bottom: var(--size-32);
    height: 3px;
    width: 100%;
  }

  & > .hidden {
    min-height: 300px;
  }
}

.carousel {
  display: flex;
  opacity: 1;
  transition: opacity var(--motion-duration-200) ease-in-out;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  cursor: grab;

  @media (--viewport-md) {
    padding-bottom: var(--size-56);
  }

  &:active {
    @media (--viewport-md) {
      cursor: grabbing;
    }
  }
}

.cardListItem {
  position: relative;

  & > .cardLine {
    @media (--viewport-md) {
      position: absolute;
      top: 0;
      right: 281px;
      height: 1362px;
      width: 801px;
    }
  }

  &:nth-child(even) {
    @media (--viewport-md) {
      padding-top: var(--size-64);
    }

    & > .cardLineScroll {
      top: 30px;
    }

    & > .cardLine {
      right: 244px;
    }
  }

  & > .card {
    height: 100%;
  }
}

.hidden {
  opacity: 0;
}

.componentNavigationButtons {
  display: none;

  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
  }
}

.progressBar {
  border-radius: 0;
  display: flex;

  @media (--viewport-md) {
    display: none;
  }

  &[value]::-webkit-progress-bar {
    background-color: var(--color-blue-200);
  }

  &[value]::-moz-progress-bar {
    background-color: var(--color-blue-200);
  }

  &::-webkit-progress-value {
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease;
  }

  &::-moz-progress-value {
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease;
  }

  &.isDark {
    &[value]::-webkit-progress-bar {
      background-color: var(--color-gray-300);
    }

    &[value]::-moz-progress-bar {
      background-color: var(--color-gray-300);
    }

    &::-webkit-progress-value {
      background-color: var(--color-white);
      transition: width var(--motion-duration-200) ease;
    }

    &::-moz-progress-value {
      background-color: var(--color-white);
      transition: width var(--motion-duration-200) ease;
    }
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  & > .cardImage {
    margin-bottom: var(--size-24);
    width: 290px;
    height: 290px;

    @media (--viewport-md) {
      min-width: 500px;
      max-width: 500px;
      height: 370px;
    }
  }
}

.cardImage {
  clip-path: var(--clip-path);
}

.content {
  @media (--viewport-md) {
    padding-left: var(--size-16);
  }

  & > .quote {
    margin-bottom: var(--size-16);
    max-width: 100%;

    @media (--viewport-md) {
      max-width: 50%;
    }
  }
}

.author {
  font-family: var(--font-family-alt-extra-bold);
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-16);
  letter-spacing: -0.02em;
}

.jobRole {
  font-size: var(--font-size-12);
}

.quote {
  font-size: var(--font-size-20);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading-sm);
  quotes: "‘" "’";
}

.navButton {
  border: 1px solid currentColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px !important;
  width: 44px !important;

  &:disabled {
    opacity: 0.4;
  }
}

.navButtonRightWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .counter {
    margin-top: var(--size-8);
  }
}

.counter {
  font-size: var(--font-size-12);
}

.cardLine {
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}
