.component {
  overflow: hidden;
  contain: style layout paint;
  isolation: isolate;
  text-rendering: optimizeSpeed;

  & > * {
    width: 100%;
  }
}

.componentBase {
  will-change: transform;
  display: flex;

  & > .items {
    margin-left: 40%;
    min-height: 155px;
  }
}

.items {
  display: flex;
  will-change: transform;
  font-size: var(--font-size-84-160);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  white-space: nowrap;
  line-height: 1;
}
