.component {
  overflow: hidden;
  background-color: var(--color-blue-800);

  & > .container {
    max-width: var(--size-container-max-width-xxl);
    margin: 0 auto;
  }
}

.container {
  @media (--viewport-md) {
    padding-top: 100px;
  }

  & > .layout {
    margin-bottom: var(--size-16);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }
}

.mobileInfoBarWrapper {
  display: flex;
  align-items: center;

  & > .infoBar {
    margin-left: var(--size-container-padding);
    margin-right: var(--size-container-padding);
  }
}

.layout {
  display: grid;
  grid-template-columns: var(--size-container-padding) 1fr var(--size-container-padding);
  position: relative;
  align-items: end;
  z-index: 0;

  @media (--viewport-md) {
    grid-template-columns: 1fr 2fr var(--size-container-padding);
  }

  @media (--viewport-lg) {
    grid-template-columns: 3fr 5fr var(--size-container-padding);
  }

  & > * {
    min-width: 0;
  }

  & > .contentBox {
    grid-column: 2;
    grid-row: 1 / -1;
    z-index: 1;
    align-self: end;

    @media (--viewport-md) {
      margin-bottom: 160px;
      margin-bottom: min(160px, 20vw);
      grid-column: 2;
    }
  }

  & > .visual {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    height: 80vw;
    max-height: 600px;
  }
}

.clippingAnimation {
  --animated-clipping-point-top-left-x: 68%;
  --animated-clipping-point-top-left-y: 40%;
  --animated-clipping-point-top-right-x: 100%;
  --animated-clipping-point-top-right-y: 40%;

  --animated-clipping-point-bottom-left-x: 68%;
  --animated-clipping-point-bottom-left-y: 40%;
  --animated-clipping-point-bottom-right-x: 100%;
  --animated-clipping-point-bottom-right-y: 40%;

  position: relative;
  filter: brightness(50%);
  clip-path: polygon(50% 0, 100% 0, 100% 65%, 80% 100%, 0 100%);

  @media (--viewport-md) {
    clip-path: polygon(
      0% 100%,
      var(--animated-clipping-point-top-left-x) var(--animated-clipping-point-top-left-y),
      var(--animated-clipping-point-top-right-x) var(--animated-clipping-point-top-right-y),
      40% 100%,
      47% 100%,
      var(--animated-clipping-point-bottom-left-x) var(--animated-clipping-point-bottom-left-y),
      var(--animated-clipping-point-bottom-right-x) var(--animated-clipping-point-bottom-right-y),
      88% 100%
    );
  }

  & > .clippingMask {
    height: 100%;
    width: auto;
    max-width: unset;
  }

  & > * {
    max-width: 100%;
  }

  @media (--viewport-md) {
    transform: translateX(-59%) translateX(calc(-1 * var(--size-32)));
    filter: unset;
  }
}

.visual {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  & > .placeholderFrame {
    height: auto;
    width: 100%;
    align-self: flex-end;

    @media (--viewport-md) {
      height: 100%;
      width: auto;
      max-width: unset;
    }
  }

  & > .clippingAnimation {
    height: 100%;
    width: auto;
    max-width: unset;
  }
}

.contentBox {
  position: relative;
  text-align: left;
  padding: var(--size-32) 0;

  & > .titleBox {
    height: 100%;
    margin-left: var(--size-container-padding);

    @media (--viewport-md) {
      margin-bottom: var(--size-16);
      margin-left: var(--size-48);
    }
  }
}

.titleBox {
  & > .title {
    max-width: 9.15em;
    height: 100%;
  }
}

.placeholderFrame {
  position: relative;

  @media (--viewport-md) {
    transform: translateX(-59%) translateX(calc(-1 * var(--size-32)));
  }
}

.clippingMask {
  & > * {
    max-width: 100%;
  }
}

.title {
  color: var(--color-white);
}

.icon {
  display: block;
}
