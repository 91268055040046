.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .lineRecruiter {
    width: 644px;
    height: 510px;

    @media (--viewport-md) {
      position: absolute;
      bottom: -260px;
      left: -240px;
      z-index: -1;
    }
  }

  & > * {
    @supports not (gap: var(--size-24)) {
      margin: calc(var(--size-24) / 2);
    }
  }

  & > .content,
  & > .coverImage {
    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  & > .title {
    margin-bottom: var(--size-16);
  }

  & > .description {
    margin-bottom: var(--size-24);
  }
}

.title {
  font-size: var(--font-size-32);
  letter-spacing: -0.3px;
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-heading);
}

.titleFirst,
.titleSecond {
  @media (--viewport-md) {
    display: block;
  }
}

.information {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .link {
    margin-bottom: var(--size-4);
  }

  & > .name {
    margin-bottom: 2px;
  }
}

.name {
  color: var(--color-blue-400);
}

.link {
  display: flex;
  align-items: flex-end;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-12);
  font-family: var(--font-family-base);
  line-height: 1;

  & > .icon {
    margin-right: var(--size-8);
  }
}

.icon {
  color: var(--color-blue-800);
}

.coverImage {
  display: flex;
  justify-content: center;

  & > .image {
    max-width: 300px;
  }
}

.lineRecruiter {
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}
