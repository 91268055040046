:root {
  --size-4: 4px;
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-18: 18px;
  --size-24: 24px;
  --size-26: 26px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-64: 64px;
  --size-80: 80px;
  --size-88: 88px;
  --size-96: 96px;
  --size-112: 112px;
  --size-136: 136px;
  --size-200: 200px;
  --size-250: 250px;

  --size-container-max-width-sm: 750px;
  --size-container-max-width-md: 870px;
  --size-container-max-width-lg: 1120px;
  --size-container-max-width-xl: 1240px;
  --size-container-max-width-xxl: 1920px;
  --size-container-padding: 5vw;
  --size-container-padding-md: calc(2.5vw + var(--size-24));
  --container-padding: 0 var(--size-container-padding);
  --container-padding-md: 0 var(--size-container-padding-md);
}
