.componentBase {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  animation: spin 700ms infinite linear;
}

.componentSmall {
  width: 1em !important;
  height: 1em !important;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  border-bottom: 2px solid currentColor;
}

.componentLarge {
  width: 3em !important;
  height: 3em !important;
  border-top: 3px solid currentColor;
  border-right: 3px solid currentColor;
  border-bottom: 3px solid currentColor;
}

@keyframes spin {
  0% { transform: rotate(0turn); }
  100% { transform: rotate(1turn); }
}
