.scrollParent {
  & > .path {
    width: 100%;
    height: 100%;
  }
}

.componentPath {
  display: block;
}
