.component {
  color: currentColor;
}

.layout {
  & > .progressBar {
    margin: 0 auto;
    height: 3px;
    width: 100%;
  }

  & > .hidden {
    min-height: 300px;
  }
}

.carousel {
  display: flex;
  opacity: 1;
  transition: opacity var(--motion-duration-200) ease-in-out;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  cursor: grab;
  padding-bottom: var(--size-40);
  padding-left: var(--size-container-padding);
  padding-right: var(--size-container-padding);

  @media (--viewport-md) {
    padding-bottom: var(--size-56);
    padding-left: var(--size-container-padding-md);
    padding-right: var(--size-container-padding-md);
  }

  & > :last-child {
    padding-right: var(--size-32);
  }

  &:active {
    @media (--viewport-md) {
      cursor: grabbing;
    }
  }
}

.cardListItem {
  min-height: 350px;
  color: var(--color-white);

  & > .card {
    height: 100%;
  }
}

.hidden {
  opacity: 0;
}

.progressBar {
  padding-left: var(--size-container-padding);
  padding-right: var(--size-container-padding);
  border-radius: 0;
  color: var(--color-blue-400);
  display: flex;

  &[value]::-webkit-progress-bar {
    background-color: var(--color-blue-200);
  }

  &[value]::-moz-progress-bar {
    background-color: var(--color-blue-200);
  }

  &::-webkit-progress-value {
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease;
  }

  &::-moz-progress-value {
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease;
  }
}

.componentCard {
  padding: var(--size-32);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 0;

  &.hasBackdrop {
    &::before {
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      background-color: var(--color-black);
      z-index: 0;
      opacity: 0.3;
    }
  }

  @media (--viewport-md) {
    padding: var(--size-56) var(--size-64);
  }

  & > .cardTitle {
    z-index: 0;

    @media (--viewport-md) {
      margin-bottom: var(--size-24);
    }
  }

  & > .description {
    z-index: 0;
  }

  & > .cardImage {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.cardImage {
  object-fit: cover;
}

.cardTitle {
  font-size: var(--font-size-140);
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
  letter-spacing: -1px;
  line-height: var(--line-height-heading);
}

.description {
  color: currentColor;
}

.componentContent {
  padding-left: var(--size-container-padding);
  padding-right: var(--size-container-padding);

  & > :not(:last-child) {
    margin-bottom: var(--size-16);
  }
}

.buttonAndDescription {
  & > .description {
    margin-bottom: var(--size-24);
  }

  & > .button {
    margin-bottom: var(--size-32);
  }
}

.subtitle {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-20);
  letter-spacing: -0.3px;
}
