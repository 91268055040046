.page {
  background-color: var(--color-white);
  padding-top: var(--size-96);
  padding-bottom: var(--size-32);
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  overflow: hidden;
  z-index: 0;

  @media (--viewport-md) {
    grid-template-columns: 1fr minmax(0, 600px) 1fr;
    padding-top: var(--size-112);
    padding-bottom: var(--size-96);
  }

  & > .shapeTop {
    position: absolute;
    left: 0;
    top: -50px;
    width: 488px;
    z-index: 0;
  }

  & > .shapeBottom {
    position: absolute;
    left: 100px;
    bottom: 0;
    width: 580px;
    z-index: 0;
  }

  & > .title {
    grid-column: 2;
    margin-top: var(--size-96);
    margin-bottom: var(--size-16);
    max-width: 100%;
    z-index: 1;
  }

  & > .content {
    grid-column: 2;
    margin-top: var(--size-88);
    z-index: 1;

    @media (--viewport-md) {
      margin-top: 0;
      margin-left: 100px;
    }
  }
}

.content {
  padding: var(--container-padding);

  @media (--viewport-md) {
    padding: 0;
  }

  & > .description {
    margin-bottom: var(--size-12);
  }
}

.title {
  padding: var(--container-padding);

  @media (--viewport-md) {
    padding: 0;
  }
}
