.page {
  & > .recruiter {
    margin-top: var(--size-24);
    margin-bottom: var(--size-88);

    @media (--viewport-md) {
      margin-top: var(--size-88);
    }
  }
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}
