.page {
  position: relative;
  z-index: 0;
  background-color: var(--color-blue-800);

  & > .hero {
    z-index: 0;
    max-height: 500px;
  }

  & > .jobs {
    z-index: 2;
  }

  & > .shape {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.shape {
  background-color: var(--color-white);
  clip-path: polygon(100vw 0, 200vw 200vw, 0 167vw);
  display: none;
  position: relative;

  @media (--viewport-md) {
    display: block;
  }

  & > .line {
    position: absolute;
    top: 300px;
    right: 0;
    width: 655px;
    height: 868px;
  }
}

.hero {
  position: relative;
  background-color: var(--color-white);
}

.jobs {
  position: relative;
}
