.componentBase {
  background-color: transparent;
  color: var(--color-white);
  padding: var(--size-12) var(--size-16);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  z-index: 0;
  height: 70px !important;
  transition: transform var(--motion-duration-300) ease-in-out,
              background-color var(--motion-duration-300) ease-in-out;

  @media (--viewport-md) {
    height: 100px !important;
    align-items: center;
    padding: var(--size-24) var(--size-48);
    gap: var(--size-16);
    grid-template-columns: minmax(100px, 1fr) 3fr 2fr;
  }

  @media (--viewport-lg) {
    grid-template-columns: minmax(100px, 1fr) auto 1fr;
  }

  & > .drawer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
  }

  &.dark:not(.coloredBackground) {
    color: var(--color-blue-800);
  }

  &.hide {
    transform: translateY(-200px);
  }

  &.coloredBackground {
    background-color: var(--color-blue-800);
  }

  & > .logo {
    width: fit-content;
  }

  & > .aside {
    grid-column: 2;
    justify-self: flex-end;

    @media (--viewport-md) {
      grid-column: 3 / 4;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
}

.slot {
  display: none;

  @media (--viewport-md) {
    display: flex;
  }

  & > .headerButton {
    @media (--viewport-md) {
      min-width: 175px;
    }
  }
}

.aside {
  display: flex;

  @media (--viewport-md) {
    justify-content: flex-end;
  }
}

.componentMenuDesktop {
  display: none;
  justify-content: center;

  @media (--viewport-md) {
    display: flex;
  }
}

.componentMenu {
  display: flex;
  gap: var(--size-24);
  font-weight: var(--font-weight-medium);

  & > * {
    @supports not (gap: var(--size-24)) {
      margin: calc(var(--size-24) / 2);
    }
  }

  & > .graphIcon {
    flex-shrink: 0;
  }
}

.link {
  position: relative;
  padding-bottom: var(--size-4);
  font-size: var(--font-size-40);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);

  @media (--viewport-md) {
    font-size: var(--font-size-16);
    font-family: var(--font-family-base);
    font-weight: var(--font-weight-regular);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease-out;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.componentGraph {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--size-4);
  color: var(--color-blue-800);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-12);
  padding: var(--size-16) var(--size-24);
  gap: var(--size-16);
  justify-content: space-between;

  & > * {
    @supports not (gap: var(--size-16)) {
      margin: var(--size-8);
    }
  }
}

.growth {
  display: flex;
  align-items: center;

  & > .triangle {
    margin-right: var(--size-8);
  }
}

.graphIcon {
  filter: drop-shadow(0 0 4px var(--color-green));
}

.triangle {
  color: var(--color-green);
  transform: rotate(180deg);
}

.hamburgerLines {
  position: relative;
  display: flex;
  place-content: center;
  flex-direction: column;

  &:hover {
    transition: transform var(--motion-duration-100) var(--ease-in-out);
    transform: scale(1.1);
  }

  &.isOpen {
    & > .lineA {
      transform: rotate(45deg);
      margin-bottom: -2px;
    }

    & > .lineB {
      transform: rotate(-45deg);
    }
  }

  & > .lineA {
    align-self: center;
    height: 2px;
    width: 24px;
    margin-bottom: var(--size-8);
  }

  & > .lineB {
    align-self: center;
    height: 2px;
    width: 24px;
  }
}

.lineA,
.lineB {
  transition: var(--motion-duration-300) var(--ease-in-out);
  display: flex;
  background-color: currentColor;
}

.componentHamburger {
  display: flex;
  align-items: center;

  @media (--viewport-md) {
    display: none;
  }

  & > .button {
    width: 48px;
    height: 48px;

    @media (--viewport-md) {
      width: 60px;
      height: 60px;
    }
  }
}

.button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .hamburgerLines {
    height: 100%;
    width: 100%;
  }
}

.active {
  color: var(--color-white);
}

.drawer {
  transform: translateX(100%);
  transition: transform var(--motion-duration-300) ease-in-out;
  background: var(--color-blue-400);
  color: var(--color-white);

  &.isActive {
    transform: translateX(0%);
  }
}

.componentDrawer {
  padding-top: 100px;
  position: relative;

  & > .mobileMenu {
    margin-left: var(--size-32);
  }

  & > .menuImage {
    position: absolute;
    bottom: -120px;
    right: 0;
  }
}

.menuImage {
  clip-path: polygon(100% 0, 100% 0, 100% 85%, 85% 100%, 0 100%);
}

.mobileMenu {
  display: flex;
  flex-direction: column;
}
