.jobsLayout {
  padding-top: var(--size-64);
  padding-bottom: var(--size-64);
  max-width: var(--size-container-max-width-sm);

  @media (--viewport-md) {
    padding-bottom: var(--size-136);
  }

  & > .filter {
    margin-bottom: var(--size-32);

    @media (--viewport-md) {
      margin-bottom: var(--size-64);
    }
  }
}

.filterTitle {
  font-size: var(--size-24);
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
}

.componentList {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.componentListItem {
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-300);
  padding: var(--size-16);
  position: relative;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.06));
  z-index: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  column-gap: var(--size-12);
  align-items: center;
  transform: translate(0, 0);
  transition: transform var(--motion-duration-200) ease-in-out;

  &:hover {
    transform: translateX(var(--size-8));

    & > .linkIndicator {
      background-color: var(--color-blue-500);
    }
  }

  @media (--viewport-md) {
    padding: var(--size-24);
  }

  & > .linkIndicator {
    min-width: 30px;
    height: 30px;
    z-index: -1;

    @media (--viewport-md) {
      width: 44px;
      height: 44px;
    }
  }
}

.wrapper {
  & > .infoBar {
    margin-top: var(--size-16);

    @media (--viewport-md) {
      margin-top: var(--size-18);
    }
  }

  & > .title {
    max-width: 80%;

    @media (--viewport-md) {
      max-width: none;
    }
  }
}

.link {
  &.relativeToParent {
    position: static;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.title {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-32);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
}

.linkIndicator {
  background-color: var(--color-blue-400);
  border-radius: 100%;
  color: var(--color-white);
  display: grid;
  place-items: center center;
  transition: transform var(--motion-duration-200) ease-in-out;
}

.componentFilter {
  & > .filterTitle {
    margin-bottom: var(--size-12);
  }
}

.layout {
  display: grid;
  grid-template-areas: "search search search"
                      "select select select";
  gap: var(--size-16);

  @media (--viewport-md) {
    grid-template-areas: "search search search select";
  }

  & > .searchWrapper {
    grid-area: search;
  }

  & > .selectWrapper {
    grid-area: select;
  }
}

.componentSearch {
  position: relative;
  z-index: 0;

  & > .search {
    height: 44px;
    width: 100%;
    z-index: 0;
  }

  & > .submit {
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    width: 50px;
    z-index: 1;
  }
}

.componentSelect {
  position: relative;
  z-index: 0;

  & > .select {
    height: 44px;
    width: 100%;
  }

  & > .chevron {
    position: absolute;
    top: 0;
    right: var(--size-12);
    width: 18px;
    z-index: 1;
    align-self: center;
    justify-self: center;
    height: 100%;
  }
}

.select {
  padding-left: var(--size-12);
  position: relative;
  background-color: var(--color-white);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid var(--color-blue-200);
  color: currentColor;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-gray-300);
  }
}

.chevron {
  display: flex;
  pointer-events: none;
}

.search {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-200);
  border-radius: 4px;
  padding-left: var(--size-12);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-gray-300);
  }
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-blue-200);
  border-right: 1px solid var(--color-blue-200);
  border-bottom: 1px solid var(--color-blue-200);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;

  & > .icon {
    height: 16px;
    width: 16px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  color: var(--color-white);
}

.componentMessage {
  color: var(--color-white);
}
