.componentBase {
  font-size: var(--font-size-14);
  display: flex;
  flex-direction: column;
  letter-spacing: -0.2px;
  z-index: 0;
  position: relative;

  & > .notification {
    z-index: -1;
  }
}

.notificationInner {
  color: var(--color-white);
  font-size: var(--font-size-12);
  overflow: hidden;
  background-color: var(--color-orange);
  border-bottom-right-radius: var(--size-4);
  border-bottom-left-radius: var(--size-4);
  padding: var(--size-10) var(--size-8) var(--size-8) var(--size-8);
}

.componentTextInput {
  position: relative;
  z-index: 0;

  & > .textLabel {
    position: absolute;
    margin-top: var(--size-14);

    &.active {
      margin-top: var(--size-8);
    }
  }

  & > .errorIcon {
    position: absolute;
    right: 0;
    top: var(--size-14);
  }
}

.dropdownIcon,
.errorIcon {
  pointer-events: none;
  color: var(--color-orange);
  display: flex;
  align-items: center;
  padding-right: var(--size-16);
}

.textInput {
  border: 1px solid var(--color-gray-300);
  border-radius: var(--size-4);
  padding: var(--size-16) var(--size-8) var(--size-8);
  background-color: var(--color-white);

  &::placeholder {
    color: transparent;
  }

  &.hasError {
    border-color: var(--color-orange);
  }
}

.textLabel {
  color: var(--color-gray-300);
  display: flex;
  align-items: center;
  padding: 0 var(--size-14);
  pointer-events: none;
  transform-origin: top left;
  transition: transform var(--motion-duration-300);

  &.active {
    transform: scale(0.6);
  }
}

.textareaLabel,
.dropdownLabel,
.formFieldLegend {
  color: var(--color-black);
  font-weight: var(--font-weight-semi-bold);
}

.componentDropdown {
  & > .dropdownLabel {
    margin-bottom: var(--size-8);
  }
}

.dropdownInner {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .dropdownIcon {
    position: absolute;
    right: 0;
    height: 100%;
  }
}

.dropdown {
  border: 1px solid var(--color-gray-300);
  border-radius: var(--size-4);
  padding: var(--size-10) var(--size-8);
  background-color: var(--color-white);

  &.hasError {
    border-color: var(--color-orange);
  }
}

.componentTextArea {
  & > .textAreaLabel {
    margin-bottom: var(--size-8);
  }

  & > .textarea {
    height: 76px;
  }
}

.textarea {
  background-color: var(--color-gray-100);
  border-radius: var(--size-4);
  resize: none;
  padding: var(--size-8);

  &.hasError {
    border: 1px solid var(--color-orange);
  }
}

.fieldset {
  border: 0;
  padding: 0;
  background-color: var(--color-white);
  border-radius: var(--size-4);

  & > :not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.componentFieldGroup {
  & > .formFieldLegend {
    margin-bottom: var(--size-8);
  }
}

.radioItem {
  position: relative;

  & > .radioInput {
    position: absolute;
    top: 2px;
  }

  & > .radioLabel {
    width: 100%;
  }
}

.radioInput {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: var(--color-gray-200);
    border: 5px solid var(--color-gray-200);
    border-radius: 50%;
    width: 18px;
    height: 18px;
  }

  &:checked {
    &::before {
      background-color: var(--color-blue-400);
    }
  }
}

.checkboxLabel {
  padding-left: var(--size-16);
  font-size: var(--font-size-12);
}

.radioLabel {
  padding-left: var(--size-26);
}

.checkboxItem {
  background-color: var(--color-white);
  display: flex;

  & > .checkboxLabel {
    width: 100%;
  }
}

.checkboxItemWrapper {
  background-color: var(--color-white);
}

.checkboxInput {
  padding: var(--size-4);
  position: relative;
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--color-white);
  border-radius: 2px 2px 0 0;

  & > :not(:last-child) {
    margin-top: 2px;
    margin-right: var(--size-8);
  }

  &.hasError {
    border-color: var(--color-orange);
  }
}

.checkbox {
  position: relative;

  &::before {
    content: '';
    background-color: var(--color-gray-200);
    height: 16px;
    width: 16px;
    display: flex;
    position: absolute;
    border-radius: 2px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
  }

  &:checked {
    &::before {
      content: '';
    }

    &::after {
      background-color: var(--color-gray-200);
      background-image: url('/images/icons/checked.svg');
      color: var(--color-white);
      background-repeat: no-repeat;
    }
  }
}

.fileInputLayout {
  position: relative;

  & > .fileInput {
    width: 100%;
  }

  & > .fileInputLabel {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.fileInput {
  position: relative;
  z-index: 0;
  cursor: pointer;

  &::file-selector-button {
    visibility: hidden;
    width: 100%;
    height: 100%;
    margin: var(--size-10);
  }
}

.fileInputLabel {
  background-color: var(--color-blue-800);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  padding: var(--size-8) 0;
  border-radius: var(--size-4);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-16);

  & > .fileInputIcon,
  & > .fileClose {
    margin-left: var(--size-8);
  }
}
