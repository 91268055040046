.page {
  position: relative;
  z-index: 0;

  & > .jobOpenings {
    margin-top: var(--size-64);
  }

  & > .topShapeLeft {
    position: absolute;
    left: 0;
    top: 450px;
    z-index: 2;
  }
}

.jobOpenings {
  position: relative;
  overflow: hidden;
}

.meetTheTeam {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: var(--color-blue-800);

  & > .shapeTeam {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 3370px !important;
    width: 2609px !important;

    @media only screen and (max-width: 1350px) {
      right: -590px;
    }
  }
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}

.topShapeLeft {
  color: var(--color-white);
  display: none;

  @media (--viewport-md) {
    display: flex;
  }
}

.shapeWrapper {
  position: relative;
  z-index: 0;

  & > .topShapeRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.shapeTeam {
  display: none;

  @media (--viewport-lg) {
    display: flex;
  }
}
