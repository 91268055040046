.component {
  color: var(--color-blue-800);
  padding-bottom: var(--size-32);
  overflow: hidden;
  --clip-path: polygon(45% 0, 115% 0, 69% 100%, 0% 100%);

  @media (--viewport-md) {
    padding-top: var(--size-80);
    padding-bottom: var(--size-80);
    --clip-path: polygon(44% 0%, 100% 0, 56% 100%, 0% 100%);
  }

  &.isWhite {
    background-color: var(--color-blue-white);
  }

  &.isBlack {
    background-color: var(--color-blue-800);
    color: var(--color-white);
  }

  &.isBlue {
    background-color: var(--color-blue-400);
    color: var(--color-white);
  }

  &.isLightBlue {
    background-color: var(--color-blue-300);
  }

  &.isGray {
    background-color: var(--color-gray-150);
  }
}

.layout {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    display: grid;
    grid-template-areas: "quote overlap image";
    grid-template-columns: 1fr 150px 1fr;
  }

  & > .quote {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-64);
      grid-column-start: quote;
      grid-column-end: overlap;
      grid-row: 1;
    }
  }

  & > .imageAndLines {
    margin-right: calc(var(--size-container-padding) * -1);

    @media (--viewport-md) {
      grid-column-start: overlap;
      grid-column-end: image;
      grid-row: 1;
      margin-right: 0;
    }
  }
}

.imageAndLines {
  & > .placeholdersLayout {
    margin-top: var(--size-40);
  }
}

.coverImage {
  clip-path: var(--clip-path);
}

.imageAndLineLayout {
  position: relative;

  & > .backgroundLine {
    position: absolute;
    right: -118px;
    top: -58px;
    height: 370px;
    width: 386px;

    @media (--viewport-md) {
      right: -53px;
      top: -30px;
      height: 370px;
      width: 498px;
    }
  }
}

.placeholdersLayout {
  position: relative;
  padding-bottom: 400px;

  @media (--viewport-md) {
    min-height: 400px;
  }

  & > .placeholderLine1,
  & > .placeholderLine2 {
    position: absolute;
    height: 370px;
    width: 498px;
  }

  & > .placeholderLine1 {
    right: 0;
    top: 30px;
  }

  & > .placeholderLine2 {
    right: -60px;
    top: 0;
  }
}
