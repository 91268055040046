.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-10);

  & > * {
    width: 100%;
  }

  & > :first-child,
  & > :nth-child(2) {
    width: 48%;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.submitButton {
  display: flex;
  flex-direction: row-reverse;
}

.componentSubmitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-medium);
  letter-spacing: -0.4px;
  padding: var(--size-8) var(--size-48);
  border-radius: var(--size-4);
  color: var(--color-white);
  background-color: var(--color-blue-400);

  & > .loader {
    margin-right: var(--size-12);
  }

  &.isDisabled {
    background-color: var(--color-gray-200);
  }
}

.consentLink {
  color: var(--color-blue-400);

  &:hover {
    text-decoration: underline;
  }
}
