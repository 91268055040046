.component {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--size-80);
  padding-top: var(--size-32);
  position: relative;
  z-index: 0;

  & > .lineBottom {
    position: absolute;
    bottom: -10px;
    right: -150px;
    z-index: -1;
  }

  & > .header {
    align-self: flex-start;
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-32);
    }
  }

  & > .jobCards {
    margin-bottom: var(--size-16);
  }

  & > .button {
    @media (--viewport-md) {
      align-self: flex-end;
      min-width: 260px;
    }
  }
}

.header {
  position: relative;

  & > .jobsCount {
    position: absolute;
    height: 36px;
    min-width: 36px;
    top: -10px;
    right: -40px;

    @media (--viewport-md) {
      top: 0;
      right: -40px;
    }
  }
}

.jobsCount {
  background-color: var(--color-blue-400);
  color: var(--color-white);
  font-size: var(--font-size-14);
  letter-spacing: -0.4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
}

.jobCards {
  & > :not(:last-child) {
    margin-bottom: var(--size-8);
  }
}

.componentJobCard {
  background-color: var(--color-white);
  display: flex;
  border: 1px solid var(--color-blue-600);
  border-radius: 4px;
  padding: var(--size-16);
  position: relative;

  @media (--viewport-md) {
    padding: var(--size-16) var(--size-26);
    align-items: center;
  }

  & > .content {
    flex-grow: 1;
  }

  & > .link {
    flex-shrink: 0;
    margin-top: var(--size-4);

    @media (--viewport-md) {
      margin-top: 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
  }

  & > .title {
    margin-bottom: var(--size-8);

    @media (--viewport-md) {
      margin-bottom: 0;
      margin-right: var(--size-56);
    }
  }
}

.title {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-20);
  letter-spacing: -0.3px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-8);

  & > * {
    @supports not (gap: var(--size-8)) {
      margin: var(--size-4);
    }
  }
}

.icon {
  height: 30px;
  width: 30px;
  background-color: var(--color-white);
  border-radius: 100%;
  display: grid;
  place-items: center;

  @media (--viewport-md) {
    height: 44px;
    width: 44px;
  }
}

.link {
  position: relative;

  &:hover {
    & > .icon {
      background-color: var(--color-blue-400);
      color: var(--color-white);
    }
  }

  &.relativeToParent {
    position: static;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
  }
}
