.component {
  background-color: var(--color-blue-800);
  color: var(--color-white);
  overflow: hidden;
}

.layout {
  display: flex;
  flex-direction: column;
  padding: var(--size-40) var(--size-32) var(--size-56) var(--size-32);
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  @media (--viewport-lg) {
    padding: var(--size-96) 0 var(--size-136) 0;
  }

  & > .logo {
    margin-bottom: var(--size-24);
    width: fit-content;
    align-self: flex-start;

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }

  & > .information {
    @media (--viewport-md) {
      margin-left: auto;
    }
  }

  & > .lineFooter {
    position: absolute;
    top: 0;
    right: -100px;
    height: 490px;
    width: 290px;

    @media (--viewport-md) {
      right: 370px;
    }
  }
}

.logo {
  display: flex;
}

.information {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  line-height: 2;

  & > .address {
    margin-bottom: var(--size-8);
  }

  & > .policyLinks {
    margin-top: var(--size-32);
  }
}

.socials {
  display: flex;
  color: var(--color-white);

  & > .socialButton {
    margin-right: var(--size-16);
  }
}

.policyLinks {
  font-size: var(--font-size-12);
}

.policyLink {
  position: relative;
  padding-bottom: var(--size-4);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease-out;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.socialButton {
  &:hover {
    color: var(--color-blue-400);
  }
}
