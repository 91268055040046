.component {
  background-color: var(--color-blue-800);
  color: var(--color-white);
  padding-top: var(--size-40);

  @media (--viewport-md) {
    padding-top: var(--size-88);
  }
}

.container {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: minmax(var(--size-container-padding), 1fr) minmax(0, var(--size-container-max-width-lg)) minmax(var(--size-container-padding), 1fr);
  padding-bottom: var(--size-24);
  position: relative;
  z-index: 0;

  & > * {
    min-width: 0;
  }

  @media (--viewport-md) {
    padding-bottom: var(--size-32);
  }

  & > .graphWrapper {
    grid-column: 2 / -1;
    grid-row: 1;
    min-width: 1281px;
    max-width: var(--size-container-max-width-xxl);
    z-index: -1;
    margin-top: 70px;
  }

  & > .content {
    grid-column: 2;
    grid-row: 1;
    z-index: 0;
    left: 5vw;

    @media (--viewport-md) {
      left: calc(2.5vw + var(--size-24));
    }
  }

  & > .disclaimer {
    grid-column: 2 / 4;
    margin-top: var(--size-16);
    max-width: 335px;
    left: var(--size-container-padding);

    @media (--viewport-md) {
      max-width: 600px;
      left: var(--size-container-padding-md);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: sticky;

  & > .title {
    @media (--viewport-md) {
      max-width: 60%;
    }
  }

  & > .button {
    margin-top: var(--size-32);
  }
}

.disclaimer {
  font-size: var(--font-size-14);
  position: sticky;
}

.button {
  padding-left: var(--size-48);
  padding-right: var(--size-48);
}

.title {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-62);
}
