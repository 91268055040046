.componentBase {
  padding-top: var(--size-96);
  padding-bottom: var(--size-96);
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding-top: var(--size-250);
    padding-bottom: var(--size-250);
  }

  & > .shape {
    width: 183px;
    height: 309px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;

    @media (--viewport-md) {
      width: 590px;
      height: 1295px;
    }
  }

  & > .title {
    margin-bottom: var(--size-32);
    z-index: 1;
  }

  & > .button {
    margin-top: var(--size-24);
    z-index: 3;
  }

  & > .marquee {
    width: 100vw;
    max-width: 100%;
  }
}

.marquee {
  overflow: hidden;
}

.title {
  font-size: var(--font-size-84-160);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
}

.button {
  display: flex;
}

.componentLight {
  color: var(--color-blue-800);
  background-color: var(--color-white);
}

.componentDark {
  color: var(--color-white);
  background-color: var(--color-blue-800);
}

.shape {
  color: var(--color-white);
  stroke: var(--color-blue-800);

  @media (--viewport-md) {
    stroke-dasharray: 0 0 1200;
  }

  &.isDark {
    color: var(--color-blue-800);
    stroke: var(--color-white);
  }
}
