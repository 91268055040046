.component {
  background-color: var(--color-white);
  color: var(--color-blue-800);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  & > .layout {
    max-width: var(--size-container-max-width-lg);
    margin: 0 auto;

    @media (--viewport-md) {
      height: var(--size-96);
    }
  }
}

.layout {
  display: grid;
  column-gap: var(--size-8);
  grid-template-areas:
    'action-buttons'
    'anchor-menu'
    'progress-bar';

  @media (--viewport-md) {
    align-items: center;
    padding-left: var(--container-size-padding-md);
    padding-right: var(--container-size-padding);
    padding-top: var(--size-10);
  }

  @media (--viewport-lg) {
    column-gap: var(--size-16);
    grid-template-columns: auto minmax(0, 300px);
    grid-template-areas:
      'anchor-menu    action-buttons'
      'progress-bar   progress-bar';
  }

  & > .actionButtons {
    grid-area: action-buttons;
  }

  & > .anchorMenu {
    grid-area: anchor-menu;
  }

  & > .progressBars {
    grid-area: progress-bar;
  }
}

.navigation {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: var(--size-8);

  @media (--viewport-md) {
    padding: 0;
    flex-wrap: wrap;
  }

  &::-webkit-scrollbar {
    height: 0 !important;
  }
}

.actionButtons {
  padding: var(--container-padding);

  @media (--viewport-md) {
    padding: 0;
  }
}

.componentLink {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.2px;
  display: flex;
  align-items: center;
  padding: var(--size-16) var(--size-32) var(--size-16) var(--size-16);
  white-space: nowrap;
  position: relative;
  transition: var(--motion-duration-300);

  @media (--viewport-md) {
    padding: var(--size-24) var(--size-32);
  }

  &.active {
    color: var(--color-blue-400);
  }

  &:hover {
    color: var(--color-blue-400);

    & > .icon {
      opacity: 1;
    }
  }

  & > .icon {
    position: absolute;
    right: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  color: var(--color-blue-400);
  padding: 0 var(--size-8);
  opacity: 0;
  transition: var(--motion-duration-300);
}

.componentButtons {
  padding-bottom: var(--size-16);
  padding-top: var(--size-16);
  background-color: var(--color-blue-800);
  display: flex;
  gap: var(--size-8);

  @media (--viewport-md) {
    padding-bottom: 0;
    padding-top: 0;
    padding-right: var(--size-8);
    padding-left: var(--size-8);
    background-color: transparent;
  }

  & > * {
    @supports not (gap: var(--size-8)) {
      margin: var(--size-4);
    }
  }

  & > .button {
    flex-grow: 1;
  }

  & > .share {
    width: 40px;

    &.expand {
      width: 150px;
    }
  }
}

.componentBar {
  & > .progress {
    height: var(--size-4);
  }
}

.progress {
  transform-origin: left;
  background-color: var(--color-blue-400);
}

.componentShare {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-800);
  border-radius: var(--size-4);
  color: var(--color-blue-400);
  display: flex;
  align-items: center;
  padding: 0 var(--size-8);
  overflow: hidden;
  transition: var(--motion-duration-300) ease;

  @media (--viewport-md) {
    border-color: var(--color-blue-400);
  }

  & > .expandShareButton,
  & > .socialsWrapper {
    flex-shrink: 0;
  }

  & > .expandShareButton {
    margin-right: var(--size-24);
  }

  &.expand {
    padding: 0 var(--size-10);
  }
}

.expandShareButton {
  &.hide {
    display: none;
  }
}

.socialsInner {
  display: flex;
  gap: var(--size-8);

  & > * {
    @supports not (gap: var(--size-8)) {
      margin: var(--size-4);
    }
  }
}
