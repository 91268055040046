.component {
  & > .shapeOutside {
    width: 100%;
    height: 100%;
  }

  & > .caption {
    margin-top: var(--size-12);

    @media (--viewport-md) {
      margin-top: var(--size-24);
    }
  }
}

.quote {
  display: block;
  text-align: left;
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-32);
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading-sm);
  quotes: "‘" "’";
}

.shapeOutside {
  float: right;
}

.caption {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: var(--font-size-16);
  font-family: var(--font-family-alt-extra-bold);
  font-weight: var(--font-weight-extra-bold);
}

.jobRole {
  font-size: var(--font-size-12);
}
