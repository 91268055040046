.component {
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: var(--font-family-alt-semi-bold);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--line-height-heading);
    letter-spacing: -0.3px;
  }

  & h1,
  & h2 {
    font-size: var(--font-size-32);
    margin-bottom: var(--size-16);
  }

  & h3 {
    font-size: var(--font-size-26);
    margin-bottom: var(--size-24);
  }

  & h4,
  & h5,
  & h6 {
    font-size: var(--font-size-22);
    margin-bottom: var(--size-16);
  }

  & p:first-of-type {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
  }

  & p {
    margin-bottom: var(--size-40);
  }

  & ol,
  & ul {
    display: block;
    padding-left: var(--size-8);
    line-height: var(--line-height-list);
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & ol {
    list-style-type: decimal;
  }

  & ul {
    list-style-type: disc;
  }

  & a {
    text-decoration: none;
    border-bottom: 1.5px solid;
    font-weight: var(--font-weight-bold);
    color: var(--color-blue-400);

    &:hover {
      border-bottom: none;
    }
  }

  & strong {
    font-weight: 700;
  }

  & > :last-child { margin-bottom: 0 !important; }
}
