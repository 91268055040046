.component {
  position: relative;
  z-index: 0;

  & > .menu,
  & > .benefits,
  & > .testimonials,
  & > .procedure {
    margin-bottom: var(--size-48);

    @media (--viewport-md) {
      margin-bottom: var(--size-112);
    }
  }

  & > .menu {
    top: 0;
    width: 100%;
    z-index: 1;

    @media (--viewport-md) {
      height: 100px;
    }
  }

  & > .description {
    margin-bottom: var(--size-48);

    @media (--viewport-md) {
      margin-bottom: var(--size-56);
    }
  }

  & > .recruiter {
    margin-top: var(--size-56);
    margin-bottom: var(--size-56);

    @media (--viewport-md) {
      margin-top: var(--size-136);
      margin-bottom: var(--size-136);
    }
  }
}

.meetTheTeam {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: var(--color-blue-800);

  & > .shapeTeam {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    height: 3370px !important;
    width: 2609px !important;

    @media only screen and (max-width: 1350px) {
      right: -590px;
    }
  }
}

.jobOpenings {
  overflow: hidden;
}

.menu {
  position: sticky;
}

.scrollMargin {
  scroll-margin-top: 120px;
}
