.componentBase {
  display: flex;
  align-items: center;
  border-radius: var(--size-4);
  font-size: var(--font-size-12);
  padding: var(--size-4) var(--size-8);
  border: 1px solid var(--color-blue-200);
  color: inherit;
  background-color: var(--color-white);
  flex-wrap: nowrap;

  & > .icon {
    margin-right: var(--size-4);
  }

  &.isDark {
    background-color: var(--color-blue-800);
    color: var(--color-white);
  }
}

.icon {
  color: var(--color-blue-600);

  &.isDark {
    color: var(--color-white);
  }
}
