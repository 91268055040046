.component_root {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  min-height: 100%;

  & > * {
    width: 100%;
  }

  & > .siteHeader {
    z-index: 2;
    position: fixed;
    height: fit-content;
  }

  & > .main {
    z-index: 1;
    position: relative;
  }

  & > .footer {
    z-index: 1;
    position: relative;
  }
}
