.component,
.componentInContent {
  display: grid;
  position: relative;

  & > .rightShape {
    @media (--viewport-md) {
      position: absolute;
      right: 0;
      top: 45%;
      height: 581px;
      width: 666px;
    }
  }

  @media (--viewport-md) {
    grid-template-columns: minmax(5vw, 1fr) minmax(0, var(--size-container-max-width-lg)) minmax(5vw, 1fr);
  }

  & > .grid {
    grid-column: 2 / -1;
    margin-bottom: var(--size-88);
    margin-top: var(--size-88);
    max-width: var(--size-container-max-width-xxl);
  }

  & > .teamSlider {
    grid-column: 1 / -1;
  }
}

.component {
  color: var(--color-white);
}

.componentInContent {
  color: var(--color-black);
}

.rightShape {
  display: none;
  color: var(--color-black);

  @media (--viewport-md) {
    display: flex;
  }
}

.teamSlider {
  display: block;
  padding-top: var(--size-96);
  padding-bottom: var(--size-32);
  overflow: hidden;
}

.componentTeamGrid {
  @media (--viewport-md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: var(--size-18);
    color: currentColor;
  }

  & > * {
    min-width: 0;
  }

  & > .content {
    @media (--viewport-md) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
  }

  & > .member1 {
    @media (--viewport-md) {
      grid-column: 1 / 2;
      grid-row: 3 / 5;
    }
  }

  & > .member2 {
    @media (--viewport-md) {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
    }
  }

  & > .member3 {
    @media (--viewport-md) {
      grid-column: 2 / 3;
      grid-row: 4 / 6;
    }
  }

  & > .member4 {
    @media (--viewport-md) {
      grid-column: 3 / 4;
      grid-row: 1 / 3;
    }
  }

  & > .member5 {
    @media (--viewport-md) {
      grid-column: 3 / 4;
      grid-row: 3 / 5;
    }
  }

  & > .member6 {
    @media (--viewport-md) {
      grid-column: 4 / 5;
      grid-row: 2 / 4;
    }
  }
}

.member {
  position: relative;
  z-index: 0;
  color: var(--color-white);

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc(100% / (6 / 7));
    pointer-events: none;
  }

  & > .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & > .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  & > .factContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.backdrop {
  background-color: rgba(28, 32, 35, 0.09);
}

.factNumber {
  font-size: var(--font-size-84-140);
  line-height: 1;
  letter-spacing: -0.04em;
}

.factTitle {
  font-size: var(--font-size-22);
  letter-spacing: -0.02em;
}

.factContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}

.image {
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;

  & > .buttonAndDescription {
    margin-top: var(--size-24);
  }
}

.buttonAndDescription {
  display: grid;
  align-items: flex-start;
  gap: var(--size-32);

  @media (--viewport-md) {
    grid-template-areas: "button description";
    grid-template-columns: 2fr 2fr;
  }

  @media (--viewport-lg) {
    grid-template-areas: "button description gutter";
    grid-template-columns: 2fr 3fr 1fr;
  }

  & > .button {
    grid-area: button;
  }

  & > .subtitleAndDescription {
    grid-area: description;
  }
}

.subtitle {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-20);
  letter-spacing: -0.3px;
}
