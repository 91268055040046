.component {
  display: flex;
  flex-direction: column;

  & > .heading {
    margin-bottom: var(--size-16);
  }

  & > .description {
    margin-bottom: var(--size-32);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .benefits {
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .buttons {
    @media (--viewport-md) {
      align-self: center;
    }
  }
}

.benefits {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-56);
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewport-lg) {
    column-gap: var(--size-96);
    row-gap: var(--size-40);
  }
}

.benefit {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .coverImage {
    margin-bottom: var(--size-16);
    width: 95px;

    @media (--viewport-md) {
      margin-bottom: var(--size-24);
    }
  }
}

.coverImage {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 100%;
  }

  & > .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.image {
  object-fit: contain;
}

.label {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  font-size: var(--font-size-16-20);
  line-height: 1.1;
  text-align: center;
  display: block;
}

.buttons {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .button {
    flex-grow: 1;

    @media (--viewport-md) {
      flex-grow: 0;
      min-width: 160px;
    }
  }
}
