.componentBase {
  overflow: auto; /* Disable margin collapse */
}

.component {
  & > .heading {
    margin-bottom: var(--size-16);
  }

  & > .subheading {
    margin-bottom: var(--size-8);
  }

  & > .paragraph,
  & > .list {
    margin-bottom: var(--size-40);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentSimple {
  & > .paragraph {
    margin-bottom: var(--size-40);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.heading {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-32);
}

.subheading {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-26);
}

.list {
  line-height: var(--line-height-list);
  padding-left: var(--size-8);
  list-style-position: inside;
}

.listOrdered {
  list-style-type: decimal;
}

.listUnordered {
  list-style-type: disc;
}
