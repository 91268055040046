.componentMd {
  font-size: var(--font-size-32);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading);
}

.componentLg {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-84);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading);
}

.componentSlantedBase {
  & > .shapeOutside {
    width: 100%;
    height: 100%;

    &.alignRight {
      margin-right: -0.25em;
    }
  }
}

.componentBase {
  font-family: var(--font-family-alt-semi-bold);
  font-weight: var(--font-weight-semi-bold);
}

.componentSlantedMedium,
.componentSlantedMediumRight {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-84);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading);
}

.componentSlantedExtraLargeRight {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-90);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading);
}

.componentSlantedExtraLargeRight,
.componentSlantedMediumRight {
  text-align: right;
}

.componentSlantedLarge {
  font-size: var(--font-size-48);
  font-size: var(--font-size-48-84);
  letter-spacing: -0.3px;
  line-height: var(--line-height-heading);
}

.shapeOutside {
  float: left;

  &.alignRight {
    float: right;
  }
}
