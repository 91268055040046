.component {
  color: inherit;
}

.componentUnderline,
.componentBlue {
  position: relative;
  padding-bottom: var(--size-4);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1.5px;
    background-color: var(--color-blue-400);
    transition: width var(--motion-duration-200) ease-out;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.componentBlue {
  color: var(--color-blue-400);
  font-weight: var(--font-weight-bold);
}
