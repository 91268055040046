.page {
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    padding-bottom: var(--size-56);
  }

  & > .title {
    margin-top: var(--size-40);
    margin-bottom: var(--size-16);
  }
}

.title {
  font-size: var(--font-size-40);
  font-weight: bold;
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }
}

.meetTheTeam {
  position: relative;
  overflow: hidden;
  z-index: 0;

  & > .shapeTeamLeft {
    width: 510px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
