.component {
  background-color: var(--color-blue-900);
  padding: var(--size-container-padding);
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-container-padding-md);
  }

  & > .layout {
    max-width: 950px;
    margin: 0 auto;
  }

  & > .coverImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  padding: var(--size-40) 0;
  gap: var(--size-24);

  & > * {
    @supports not (gap: var(--size-24) {
      margin: calc(var(--size-24) / 2);

      @media (--viewport-md) {
        margin: calc(var(--size-40) / 2);
      }
    }
  }

  @media (--viewport-md) {
    flex-direction: row;
    padding: var(--size-48) 0;
    gap: var(--size-40);
  }

  & > .content,
  & > .form {
    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.description {
  font-size: var(--font-size-22);
}

.content {
  color: var(--color-white);

  @media (--viewport-md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & > .description {
    margin-top: var(--size-8);
  }
}

.form {
  background-color: var(--color-white);
  padding: var(--size-24) var(--size-16);

  @media (--viewport-md) {
    padding: var(--size-32);
  }
}

.coverImage {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: rgba(16, 21, 27, 0.6);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  & > .image {
    width: 100%;
    height: 100%;
  }
}
